import axios from 'axios';

// Actions
const FETCH_BILLING_PLANS_BEGIN = 'FETCH_BILLING_PLANS_BEGIN';
const FETCH_BILLING_PLANS_SUCCESS = 'FETCH_BILLING_PLANS_SUCCESS';
const FETCH_BILLING_PLANS_ERROR = 'FETCH_BILLING_PLANS_ERROR';

// Action Creators
export function fetchBillingPlans({ shouldUseTwoPointOh = undefined }) {
  return async dispatch => {
    dispatch(fetchBillingPlansBegin());
    try {
      const response = await axios.get('/api/v1/billing_plans', {
        params: {
          include: 'billing_category,plan_feature_set,addons',
          'use_two_point_oh': shouldUseTwoPointOh,
        }
      });
      dispatch(fetchBillingPlansSuccess(response.data.billing_plans));
    } catch (e) {
      dispatch(fetchBillingPlansError(e));
    }
  }
}

export const fetchBillingPlansBegin = () => ({
  type: FETCH_BILLING_PLANS_BEGIN
});

export const fetchBillingPlansSuccess = (data) => ({
  type: FETCH_BILLING_PLANS_SUCCESS,
  data
});

export const fetchBillingPlansError = (error) => ({
  type: FETCH_BILLING_PLANS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_BILLING_PLANS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_BILLING_PLANS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case FETCH_BILLING_PLANS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
