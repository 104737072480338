import React, { useState, useEffect } from 'react';
import BillingPlan from 'components/BillingPlan';
import { InlineGrid, Layout } from '@shopify/polaris';

export default function BillingPlans({
  planActive,
  activeBillingPlan,
  activeBillingSubscription,
  billingPlans,
  chooseLoading,
  choosePlan,
  cancelLoading,
  twoPointOh = false,
}) {
  const [planGroups, setPlanGroups] = useState(null);

  function compare(a, b) {
    if (a.free === true) {
      return 1;
    }

    if (b.free === true) {
      return -1;
    }

    return a.base_price?.cents - b.base_price?.cents;
  }

  useEffect(() => {
    if (billingPlans) {
      const groupedPlans = {};
      for (const billingPlan of billingPlans.sort(compare)) {
        const category = billingPlan?.billing_category?.name;
        if (groupedPlans[category]) {
          groupedPlans[category] = [...groupedPlans[category], billingPlan];
        } else {
          groupedPlans[category] = [billingPlan];
        }
      }

      setPlanGroups(Object.values(groupedPlans));
    }
  }, [billingPlans]);

  if (!planGroups?.length) {
    return null;
  }

  return (
    <Layout.Section>
      <InlineGrid
        columns={{
          'xs': 1,
          'sm': 1,
          'md': planGroups?.length - 1 || 1,
          'lg': planGroups?.length || 1,
          'xl': planGroups.length || 1,
        }}
        gap="200"
      >
        {
          planGroups.map((planGroup) =>
            <BillingPlan
              key={planGroup[0]?.id}
              planActive={planActive}
              activeBillingPlan={activeBillingPlan}
              activeBillingSubscription={activeBillingSubscription}
              planGroup={planGroup}
              length={planGroups.length}
              chooseLoading={chooseLoading}
              choosePlan={choosePlan}
              cancelLoading={cancelLoading}
              twoPointOh={twoPointOh}
            />
          )
        }
      </InlineGrid>
    </Layout.Section>
  );
}
