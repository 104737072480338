import React, { useState } from "react";
import { useSelector } from "react-redux";
import { List, Modal, Text, BlockStack } from "@shopify/polaris";
import billingPlanDetails, {
  getLostFeatures,
} from "utils/billingPlanDetails";
import CostBreakdown from "./CostBreakdown";

export default function DowngradeModal({
  open,
  onClose,
  activePlan,
  selectedPlan,
  twoPointOh,
  choosePlan,
}) {
  const { data: storesData } = useSelector((state) => state.stores);
  const totalStores = storesData?.length;

  const [currentStep, setCurrentStep] = useState(1);

  const {
    name,
  } = billingPlanDetails(selectedPlan, twoPointOh);

  const storeAddon = selectedPlan?.addons?.find(
    (a) => a.feature_name === "pf_store_limit"
  );
  const selectedPlanStoreLimit = selectedPlan?.plan_feature_set?.pf_store_limit;
  const storeLimitExceeded = selectedPlanStoreLimit < totalStores;
  const storeDiff = totalStores - selectedPlanStoreLimit;

  const handleClose = () => {
    setCurrentStep(1);
    onClose();
  }

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleDowngrade = async () => {
    // We need to create a billing item for each store over the limit
    let billingItems = [];
    for (let i = 0; i < storeDiff; i++) {
      billingItems.push({
        billing_addon_id: storeAddon?.id,
      });
    }

    await choosePlan(selectedPlan, billingItems);
    handleClose();
  }

  const primaryAction = currentStep === 1 && storeLimitExceeded
    ? { content: "Yes, continue", onAction: handleNext }
    : { content: "Downgrade plan", onAction: handleDowngrade, primary: true, tone: "success" };

  return (
    <Modal
      open={open}
      title={currentStep !== 2 ? "Are you sure you want to downgrade?" : "Confirm add-on pricing"}
      onClose={handleClose}
      primaryAction={primaryAction}
      secondaryActions={[{ content: "Cancel", onAction: handleClose }]}
    >
      <Modal.Section>
        {currentStep === 1 && (
          <BlockStack gap="200">
            <Text>
              You'll lose access to the following features by downgrading to {name}:
            </Text>
            <List type="bullet">
              {getLostFeatures(activePlan, selectedPlan)?.map(
                (feature) => (
                  <List.Item key={feature}>{feature}</List.Item>
                )
              )}
            </List>
          </BlockStack>
        )}
        {currentStep === 2 && storeLimitExceeded && (
          <BlockStack gap="400">
            <Text>
              You are downgrading to a plan that only includes {selectedPlanStoreLimit} store{selectedPlanStoreLimit > 1 ? "s" : ""}.<br />
              Any additional stores on your plan will be charged as add-ons.
            </Text>
            <CostBreakdown plan={selectedPlan} />
          </BlockStack>
        )}
      </Modal.Section>
    </Modal>
  );
}
