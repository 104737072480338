import React from 'react';
import { useSelector } from 'react-redux';
import { Text, BlockStack, InlineStack } from "@shopify/polaris";
import billingPlanDetails, { formatCurrency } from 'utils/billingPlanDetails';

export default function CostBreakdown({ plan }) {
  const organizations = useSelector(state => state.organizations);
  const twoPointOh = organizations?.data?.find(o => o.scoped === true)?.two_point_oh;
  const { data: storesData } = useSelector((state) => state.stores);
  const totalStores = storesData?.length;

  const {
    price,
    storeAddonPrice,
  } = billingPlanDetails(plan, twoPointOh);

  const storeAddon = plan?.addons?.find(
    (a) => a.feature_name === "pf_store_limit"
  );
  const selectedPlanStoreLimit = plan?.plan_feature_set?.pf_store_limit;
  const storeDiff = totalStores - selectedPlanStoreLimit;

  const totalCost = () => {
    const basePrice = plan?.base_price;
    return formatCurrency({
      cents: basePrice.cents + storeAddon?.price_cents * (Math.max(storeDiff, 0)),
      currency_iso: basePrice.currency_iso,
    }) || "$0";
  }

  return (
    <BlockStack gap="400">
      <Text variant="headingSm">Cost breakdown</Text>
      <BlockStack gap="200">
        <BlockStack>
          <InlineStack align="space-between">
            <Text fontWeight="semibold">Base price</Text>
            <Text>{price}</Text>
          </InlineStack>
          <Text variant="subdued">Includes {selectedPlanStoreLimit} store{selectedPlanStoreLimit > 1 ? "s" : ""}</Text>
        </BlockStack>
        {storeDiff > 0 &&
          <BlockStack>
            <InlineStack align="space-between">
              <Text fontWeight="semibold">Add-ons</Text>
              <Text>{formatCurrency({ cents: storeAddon?.price_cents * (storeDiff), currency_iso: "USD" })}</Text>
            </InlineStack>
            <Text variant="subdued">{storeDiff} store{storeDiff > 1 ? "s" : ""} ({storeAddonPrice || "$0"} x {storeDiff})</Text>
          </BlockStack>
        }
      </BlockStack>
      <InlineStack align="space-between">
        <Text variant="headingSm">Total monthly payment</Text>
        <Text variant="headingSm">{totalCost()}</Text>
      </InlineStack>
    </BlockStack>
  );
}