export default function billingPlanDetails(billingPlan, twoPointOh = false) {
  const planName = billingPlan?.name?.toLowerCase();
  const planFeatureSet = billingPlan?.plan_feature_set;

  switch (planName) {
    case 'free':
      if (twoPointOh) {
        return {
          name: billingPlan.name,
          description: 'Basic tooling for new stores. Ad supported.',
          recommended: false,
          price: formatCurrency(billingPlan.base_price) || '$0',
          storeAddonPrice: formatCurrency({ cents: billingPlan?.addons?.find(a => a.feature_name === 'pf_store_limit')?.price_cents, currency_iso: "USD" }),
          features: [
            true,
            true,
            planFeatureSet?.pf_advanced_display_settings,
            planFeatureSet?.pf_review_incentives,
            planFeatureSet?.pf_advanced_incentives,
            planFeatureSet?.pf_integrations,
            planFeatureSet?.pf_google_shopping,
            planFeatureSet?.pf_attributes,
            planFeatureSet?.pf_product_aliasing,
            planFeatureSet?.pf_featured_reviews,
            planFeatureSet?.pf_media_gallery,
            planFeatureSet?.pf_custom_emails,
            planFeatureSet?.pf_syndication,
            planFeatureSet?.pf_user_limit,
            planFeatureSet?.pf_store_limit,
            planFeatureSet?.pf_review_tagging,
            planFeatureSet?.pf_custom_apps,
            true,
            'Limited',
          ],
        }
      }
      return {
        name: billingPlan.name,
        description: 'Basic tooling for new stores, up to 50 orders a month',
        altDescription: 'Must-have features for entreprenuers who want a professionally built reviews solution.',
        recommended: false,
        price: formatCurrency(billingPlan.base_price) || '$0',
        features: [
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          false,
          true,
          'Limited',
          false
        ]
      };
    case 'essentials':
      return {
        name: billingPlan.name,
        description: 'The core tools for businesses that are getting started',
        altDescription: 'Core features for small businesses on track to grow.',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        features: [
          true,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          false,
          true,
          true,
          false
        ]
      };
    case 'standard':
      return {
        name: billingPlan.name,
        description: 'Everything you need for a growing business',
        altDescription: 'For mid-size businesses who want a reviews solution that will drive engagement and growth.',
        recommended: true,
        price: formatCurrency(billingPlan.base_price),
        features: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          false,
          false,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          false,
          true,
          true,
          false
        ]
      };
    case 'pro':
      return {
        name: billingPlan.name,
        description: 'Advanced customization & support for scaling businesses',
        altDescription: 'Advanced features for medium to large businesses that need the utmost customizability.',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        features: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          `${billingPlan?.plan_feature_set?.pf_user_limit}`,
          `Up to ${billingPlan?.plan_feature_set?.pf_store_limit} stores`,
          true,
          true,
          true
        ]
      };
    case 'growth':
      if (twoPointOh) {
        return {
          name: billingPlan.name,
          description: 'Everything you need for a growing business',
          recommended: false,
          price: formatCurrency(billingPlan.base_price),
          storeAddonPrice: formatCurrency({ cents: billingPlan?.addons?.find(a => a.feature_name === 'pf_store_limit')?.price_cents, currency_iso: "USD" }),
          features: [
            true,
            true,
            planFeatureSet?.pf_advanced_display_settings,
            planFeatureSet?.pf_review_incentives,
            planFeatureSet?.pf_advanced_incentives,
            planFeatureSet?.pf_integrations,
            planFeatureSet?.pf_google_shopping,
            planFeatureSet?.pf_attributes,
            planFeatureSet?.pf_product_aliasing,
            planFeatureSet?.pf_featured_reviews,
            planFeatureSet?.pf_media_gallery,
            planFeatureSet?.pf_custom_emails,
            planFeatureSet?.pf_syndication,
            planFeatureSet?.pf_user_limit,
            planFeatureSet?.pf_store_limit,
            planFeatureSet?.pf_review_tagging,
            planFeatureSet?.pf_custom_apps,
            true,
            true,
          ],
          summary: [
            '1 storefront + $69/month for each additional store',
            '2 external channels + $25/month for each additional channel',
            'Marketing integrations (Klaviyo, Drip, Omnisend, Postscript)',
            'All Core features',
          ]
        }
      }
      return {
        name: billingPlan.name,
        description: `Per ${billingPlan.bucket_size} orders. Must have features for all businesses who want a review solution that will drive engagement and growth.`,
        recommended: false,
        price: formatCurrency(billingPlan.bucket_price),
      };
    case 'credit':
    case 'trial':
      return {
        name: billingPlan.name,
        description: 'Try out Junip risk-free.',
        recommend: false,
        price: formatCurrency(billingPlan.base_price)
      }
    case 'premium':
      return {
        name: billingPlan.name,
        description: 'Advanced customization & support for scaling businesses',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        storeAddonPrice: formatCurrency({ cents: billingPlan?.addons?.find(a => a.feature_name === 'pf_store_limit')?.price_cents, currency_iso: "USD" }),
        features: [
          true,
          true,
          planFeatureSet?.pf_advanced_display_settings,
          planFeatureSet?.pf_review_incentives,
          planFeatureSet?.pf_advanced_incentives,
          planFeatureSet?.pf_integrations,
          planFeatureSet?.pf_google_shopping,
          planFeatureSet?.pf_attributes,
          planFeatureSet?.pf_product_aliasing,
          planFeatureSet?.pf_featured_reviews,
          planFeatureSet?.pf_media_gallery,
          planFeatureSet?.pf_custom_emails,
          planFeatureSet?.pf_syndication,
          planFeatureSet?.pf_user_limit,
          planFeatureSet?.pf_store_limit,
          planFeatureSet?.pf_review_tagging,
          planFeatureSet?.pf_custom_apps,
          true,
          true,
        ],
        summary: [
          '3 storefronts + $99/month per additional store',
          '4 external channels + $25/month per additional channel',
          'Review tagging',
          'Display API',
          'Custom apps',
          'All Growth features',
        ]
      }
    case 'core':
      return {
        name: billingPlan.name,
        description: 'Tools to start leveraging reviews',
        recommended: false,
        price: formatCurrency(billingPlan.base_price),
        storeAddonPrice: formatCurrency({ cents: billingPlan?.addons?.find(a => a.feature_name === 'pf_store_limit')?.price_cents, currency_iso: "USD" }),
        features: [
          true,
          true,
          planFeatureSet?.pf_advanced_display_settings,
          planFeatureSet?.pf_review_incentives,
          planFeatureSet?.pf_advanced_incentives,
          planFeatureSet?.pf_integrations,
          planFeatureSet?.pf_google_shopping,
          planFeatureSet?.pf_attributes,
          planFeatureSet?.pf_product_aliasing,
          planFeatureSet?.pf_featured_reviews,
          planFeatureSet?.pf_media_gallery,
          planFeatureSet?.pf_custom_emails,
          planFeatureSet?.pf_syndication,
          planFeatureSet?.pf_user_limit,
          planFeatureSet?.pf_store_limit,
          planFeatureSet?.pf_review_tagging,
          planFeatureSet?.pf_custom_apps,
          true,
          true,
        ],
        summary: [
          'Photo & video reviews',
          'Google review snippets',
          'General & media incentives',
          'Product grouping',
          'Media galleries',
        ]
      }
    default:
      return { features: [] };
  }
}

function getTwoPointOhFeatures(userLimit, storeLimit) {
  return [
    'Branding',
    'Analytics',
    'Advanced on-site configuration',
    'Incentives',
    'Media incentives',
    'Integrations',
    'Google Shopping',
    'Custom questions',
    'Product grouping',
    'Featured reviews',
    'Media galleries',
    'Custom HTML emails',
    'Review syndication',
    `${userLimit} user account${userLimit > 1 ? "s" : ""}`,
    `${storeLimit} storefront${storeLimit > 1 ? "s" : ""} included`,
    'Review tagging',
    'API & Custom apps',
    'Knowledge base',
    'Email & chat support',
  ];
}

// This function accepts two billing plans and returns an array of features
// that are present in the current plan but not in the new plan.
export function getLostFeatures(currentPlan, newPlan) {
  const TWO_POINT_OH_FEATURES = getTwoPointOhFeatures(
    currentPlan?.plan_feature_set?.pf_user_limit,
    currentPlan?.plan_feature_set?.pf_store_limit,
  );

  const currentFeatures = billingPlanDetails(currentPlan, true)?.features || [];
  const newFeatures = billingPlanDetails(newPlan, true)?.features || [];

  return currentFeatures?.map((feature, i) => {
    if (feature && (newFeatures[i] === false || newFeatures[i] < feature)) {
      return i;
    } else {
      return null;
    }
  }).filter(feature => feature !== null)
    .map(feature => TWO_POINT_OH_FEATURES[feature]);
}

export const formatCurrency = ({ cents, currency_iso }) => {
  if (cents) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency_iso,
      minimumFractionDigits: 0
    }).format(cents / 100);
  }
};
