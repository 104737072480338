import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { trackEvent } from 'utils/Mixpanel';
import { connect } from 'react-redux';
import { fetchBillingPlans } from 'redux/billingPlans';
import { fetchPlanFeatureSets } from 'redux/planFeatureSets';
import { fetchStripeRecords } from 'redux/stripeRecords';
import { showToast } from 'redux/toast';
import { Button, Page, LegacyStack, LegacyCard, Layout, Collapsible, Text } from "@shopify/polaris";
import JunipLogo from 'components/JunipLogo';
import SkipOnboarding from 'components/SkipOnboarding';
import LoadingWrapper from 'components/LoadingWrapper';
import StripeModal from 'components/StripeModal';
import BillingPlans from 'components/BillingPlans';
import BillingPlansTable from 'components/BillingPlansTable';
import OfferCard from 'components/OfferCard';

const NEXT_STEP = '/onboarding/thanks';

function SelectPlan({
  billingPlans,
  stripeRecords,
  fetchBillingPlans,
  fetchStripeRecords,
  fetchPlanFeatureSets,
  showToast,
  history,
  progress
}) {
  const [orgLoading, setOrgLoading] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [organization, setOrganization] = useState({});
  const [stripeModalData, setStripeModalData] = useState(null);
  const [chooseLoading, setChooseLoading] = useState(false);

  useEffect(() => {
    async function fetchOrganization() {
      setOrgLoading(true);
      const response = await axios.get('/api/v1/organizations', {
        params: {
          include: 'referral_record',
          'filter[scoped_only]': true
        }
      });

      setOrganization(response.data?.organizations?.[0]);
      setOrgLoading(false);
    }

    fetchOrganization();
  }, []);

  useEffect(() => {
    if (organization.payment_provider === 'stripe' && !stripeRecords.data) {
      fetchStripeRecords();
    }
  }, [organization, fetchStripeRecords, stripeRecords.data]);

  useEffect(() => {
    if (!billingPlans.data) {
      fetchBillingPlans({});
    }
  }, [fetchBillingPlans, billingPlans.data]);

  const choosePlan = async (plan) => {
    setChooseLoading(true);
    if (organization.billable === false || plan.free === true) {
      await createBillingSubscription(plan.id, 'junip');
    } else if (organization.payment_provider === 'shopify') {
      await createBillingSubscription(plan.id);
    } else if (organization.payment_provider === 'stripe') {
      setStripeModalData({
        plan,
        action: 'select'
      });
    }

    trackEvent('Onboarding billing - Select');
  };

  const createBillingSubscription = async (planId, provider) => {
    try {
      const billingSubscriptionResponse = await axios.post('/api/v1/billing_subscriptions', {
        billing_subscription: {
          billing_plan_id: planId,
          provider: provider || organization.payment_provider
        }
      });
      const newBillingSubscription = billingSubscriptionResponse.data.billing_subscription;

      if (newBillingSubscription.authorization_url) {
        window?.localStorage?.setItem('backToOnboarding', true);
        window.location.href = newBillingSubscription.authorization_url;
      } else {
        setChooseLoading(false);
        showToast('Plan selected');
        fetchPlanFeatureSets();
        history.push(NEXT_STEP);
      }
      trackEvent('Onboarding billing - Select plan');
    } catch (e) {
      showToast('Something went wrong while selecting this plan, please check your payment method and try again', true);
      setChooseLoading(false);
      trackEvent('Error: Onboarding billing - Select plan', { statusCode: e?.response?.status });
    }
  };

  const hasOffer = () => {
    if (organization?.payment_provider === 'stripe' && !organization.referrer_discount_used && organization.referral_record) {
      return true;
    }

    return false;
  };

  const handleToggle = useCallback(() => setShowTable((open) => !open), []);

  return (
    <Page>
      <LegacyCard>
        <LegacyCard.Section>
          <div className="o-step text-center">
            <LegacyStack vertical spacing="loose" alignment="center">
              <JunipLogo height={'32px'} />
              <Text variant="heading2xl" as="p">Start your 14 day trial on the plan that suits you</Text>
              <p>
                <Text variant="bodyMd" as="span" tone="subdued">Junip offers a variety of plans for all types of businesses. Select the plan that best suits your needs. Don't worry, you'll have 14 days to trial completely free.</Text>
              </p>
            </LegacyStack>
          </div>
        </LegacyCard.Section>
        <LegacyCard.Section subdued>
          <LoadingWrapper
            loading={
              billingPlans.loading ||
              orgLoading
            }
            sections={2}
          >
            <Layout>
              {hasOffer() &&
                <OfferCard offer={organization?.referral_record} />
              }
              <BillingPlans
                planActive={false}
                activeBillingPlan={{}}
                activeBillingSubscription={{}}
                cancelLoading={false}
                chooseLoading={chooseLoading}
                choosePlan={choosePlan}
                billingPlans={billingPlans.data?.filter(b => !b.free)}
              />
              <Layout.Section variant="fullWidth">
                <div className="pt-4 pb-4 text-center">
                  <Button
                    onClick={handleToggle}
                    ariaExpanded={showTable}
                    ariaControls="plan-details-collapsible"
                    disclosure={showTable ? 'up' : 'down'}
                  >
                    {showTable ? 'Hide' : 'View'} plan details
                  </Button>
                </div>
              </Layout.Section>
              <Layout.Section variant="fullWidth">
                <Collapsible
                  open={showTable}
                  id="plan-details-collapsible"
                  expandOnPrint
                >
                  <BillingPlansTable billingPlans={billingPlans.data} organization={organization} />
                </Collapsible>
              </Layout.Section>
            </Layout>
          </LoadingWrapper>
        </LegacyCard.Section>
        <LegacyCard.Section subdued>
          <LegacyStack alignment="center">
            <LegacyStack.Item fill>
              {progress}
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button
                size="large"
                url={NEXT_STEP}
                onClick={() => trackEvent('Onboarding billing - Continue with free')}
                variant="default"
              >
                Continue with free
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <SkipOnboarding disabled={chooseLoading} />
      <StripeModal
        showToast={showToast}
        modalData={stripeModalData}
        setModalData={setStripeModalData}
        stripeRecord={stripeRecords.data?.[0]}
        fetchStripeRecords={fetchStripeRecords}
        createBillingSubscription={createBillingSubscription}
        setChooseLoading={setChooseLoading}
      />
    </Page>
  );
}

const mapStateToProps = (state) => ({
  organizations: state.organizations,
  stripeRecords: state.stripeRecords,
  billingPlans: state.billingPlans
});

const mapDispatchToProps = (dispatch) => ({
  fetchStripeRecords: () => dispatch(fetchStripeRecords()),
  fetchPlanFeatureSets: () => dispatch(fetchPlanFeatureSets()),
  fetchBillingPlans: () => dispatch(fetchBillingPlans({})),
  showToast: (message, error) => dispatch(showToast(message, error))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPlan);

