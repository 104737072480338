import axios from 'axios';

// Actions
const FETCH_BILLING_SUBSCRIPTIONS_BEGIN = 'FETCH_BILLING_SUBSCRIPTIONS_BEGIN';
const FETCH_BILLING_SUBSCRIPTIONS_SUCCESS = 'FETCH_BILLING_SUBSCRIPTIONS_SUCCESS';
const FETCH_BILLING_SUBSCRIPTIONS_ERROR = 'FETCH_BILLING_SUBSCRIPTIONS_ERROR';
const UPDATE_BILLING_SUBSCRIPTION = 'UPDATE_BILLING_SUBSCRIPTION';
const DEACTIVATE_BILLING_SUBSCRIPTION = 'DEACTIVATE_BILLING_SUBSCRIPTION';
const ADD_BILLING_SUBSCRIPTION = 'ADD_BILLING_SUBSCRIPTION';

// Action Creators
export function fetchBillingSubscriptions() {
  return async dispatch => {
    dispatch(fetchBillingSubscriptionsBegin());
    try {
      const response = await axios.get('/api/v1/billing_subscriptions', {
        params: {
          include: 'billing_plan,items,items.billing_addon',
          'filter[active]': true
        }
      });
      dispatch(fetchBillingSubscriptionsSuccess(response.data.billing_subscriptions));
    } catch (e) {
      dispatch(fetchBillingSubscriptionsError(e));
    }
  }
}

export const updateBillingSubscription = (data) => ({
  type: UPDATE_BILLING_SUBSCRIPTION,
  data,
});

export const addBillingSubscription = (data) => ({
  type: ADD_BILLING_SUBSCRIPTION,
  data,
});

export const deactivateBillingSubscription = () => ({
  type: DEACTIVATE_BILLING_SUBSCRIPTION
});

export const fetchBillingSubscriptionsBegin = () => ({
  type: FETCH_BILLING_SUBSCRIPTIONS_BEGIN
});

export const fetchBillingSubscriptionsSuccess = (data) => ({
  type: FETCH_BILLING_SUBSCRIPTIONS_SUCCESS,
  data
});

export const fetchBillingSubscriptionsError = (error) => ({
  type: FETCH_BILLING_SUBSCRIPTIONS_ERROR,
  data: { error }
});

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_BILLING_SUBSCRIPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_BILLING_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case UPDATE_BILLING_SUBSCRIPTION:
      const index = state.data.findIndex(b => b.id === action.data.id);
      state.data[index] = action.data;
      return {
        ...state,
        loading: false,
        error: null
      };
    case DEACTIVATE_BILLING_SUBSCRIPTION:
      const activeIndex = state.data.findIndex(b => b.active === true);
      if (activeIndex !== -1) {
        state.data[activeIndex].active = false;
      }
      return {
        ...state,
        loading: false,
        error: null
      };
    case ADD_BILLING_SUBSCRIPTION:
      state.data.push(action.data);
      return {
        ...state,
        loading: false,
        error: null
      };
    case FETCH_BILLING_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.data.error
      };
    default:
      return state;
  }
}
