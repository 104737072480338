import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import axios from 'axios';
import { Modal, Form, FormLayout, TextField, Link, Text, BlockStack } from "@shopify/polaris";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import 'styles/components/StripeModal.scss';
import CostBreakdown from './CostBreakdown';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#000",
      fontFamily: '-apple-system, system-ui, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function StripeModal({
  modalData,
  setModalData,
  stripeRecord,
  fetchStripeRecords,
  createBillingSubscription,
  setChooseLoading,
  showToast
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const organizations = useSelector(state => state.organizations);
  const twoPointOh = organizations?.data?.find(o => o.scoped === true)?.two_point_oh;

  useEffect(() => {
    if (stripeRecord) {
      setEmail(stripeRecord.email);
    }
  }, [stripeRecord]);

  const closeModal = () => {
    setModalData(null);
    if (setChooseLoading) {
      setChooseLoading(false);
    }
  };

  const renderText = () => {
    let text = {
      title: 'Add your payment method',
      cta: `Select ${modalData?.plan?.name} plan`,
      body: ''
    };

    if (modalData?.action === 'edit') {
      text.title = 'Update your payment method';
      text.cta = 'Update';
    } else if (modalData?.action === 'select' && stripeRecord) {
      if (twoPointOh) {
        text.title = "Update your plan"
        text.cta = `Select ${modalData?.plan?.name} plan`;
        text.body = (
          <BlockStack gap="400">
            <Text>
              The payment method on file (
              <span style={{ textTransform: 'capitalize' }}>
                {stripeRecord.card_brand !== 'unkown' ? stripeRecord.card_brand : 'Card'}{' '}
              </span>
              •••• {stripeRecord.card_last4}
              ) will be charged.
            </Text>
            <CostBreakdown plan={modalData?.plan} />
          </BlockStack>
        )
      } else {
        text.title = `Select ${modalData?.plan?.name} plan?`
        text.cta = `Select ${modalData?.plan?.name} plan`;
        text.body = `Are you sure you want to select the ${modalData?.plan?.name} plan? These changes will apply immediately.`;
      }
    }

    return text;
  };

  const handleChange = useCallback((newValue) => { setEmailError(false); setEmail(newValue) }, []);

  const selectPlan = () => {
    createBillingSubscription(modalData?.plan?.id, null, modalData?.billingItems);
    setModalData(null);
    trackEvent(`Stripe modal - Select ${modalData?.plan?.name} plan`);
  };

  const createStripeRecord = async (payment_method) => {
    try {
      await axios.post(`/api/v1/stripe_records`, {
        stripe_record: {
          email,
          payment_method
        }
      });
      fetchStripeRecords();
      selectPlan();
      trackEvent('Stripe modal - Submit payment method');
    } catch (e) {
      showToast('Error adding your payment method, please try again', true);
      trackEvent('Error: Stripe modal - Add payment method', { statusCode: e?.response?.status });
    }
  };

  const updateStripeRecord = async (payment_method) => {
    try {
      await axios.put(`/api/v1/stripe_records/${stripeRecord.id}`, {
        stripe_record: {
          email,
          payment_method
        }
      });
      fetchStripeRecords();
      closeModal();
      trackEvent('Stripe modal - Update payment method');
      showToast('Payment method updated');
    } catch (e) {
      showToast('Error updating your payment method, please try again', true);
      trackEvent('Error: Stripe modal - Update payment method');
    }
  };

  const handleSubmit = async () => {
    if (!email.includes('@')) {
      setEmailError(true);
      return;
    }

    if (loading) {
      return;
    }

    if (modalData?.action === 'select' && stripeRecord) {
      selectPlan();
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (error) {
      showToast('Error adding payment method, please check your card information and try again', true);
      trackEvent('Error: Stripe modal - Invalid payment method');
    } else {
      if (modalData?.action === 'edit') {
        await updateStripeRecord(paymentMethod.id);
      } else {
        await createStripeRecord(paymentMethod.id);
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      open={modalData !== null}
      title={renderText().title}
      onClose={() => !loading && closeModal()}
      primaryAction={{
        content: renderText().cta,
        loading: loading,
        disabled: !elements || !stripe || loading,
        onAction: handleSubmit,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: loading,
          onAction: () => { closeModal(); },
        }
      ]}
    >
      <Modal.Section>
        {modalData?.action === 'select' && stripeRecord
          ? <p>{renderText().body}</p>
          : <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                value={email}
                name="email"
                onChange={handleChange}
                label="Billing email"
                type="email"
                autoComplete="email"
                placeholder="Enter your billing email"
                autoFocus={true}
                helpText="This email will receive payment updates and invoices"
                error={emailError && 'Please enter a valid email address'}
              />
              <div>
                <label>
                  Card details
                  <CardElement options={CARD_ELEMENT_OPTIONS} />
                </label>
                <div className="mt-1">
                  <Text variant="bodyMd" as="span" tone="subdued">Powered by <Link external url="https://stripe.com">Stripe</Link></Text>
                </div>
              </div>
            </FormLayout>
          </Form>
        }
      </Modal.Section>
    </Modal>
  );
}
