import React from 'react';
import { useSelector } from 'react-redux';
import {
  Page,
  Icon,
  Text,
  BlockStack,
  ResourceList,
  Card
} from "@shopify/polaris";

import {
  DesktopIcon,
  FlagIcon,
  EmailNewsletterIcon,
  NotificationIcon,
  ReceiptDollarIcon,
  StoreIcon,
  ProfileIcon,
  TeamIcon,
  ChatReferralIcon,
  CodeIcon,
  ColorIcon,
} from "@shopify/polaris-icons";

import 'styles/routes/Settings.scss';

export default function Settings() {
  const organization = useSelector((state) => state.organization);

  const sections = [
    {
      title: 'Organization settings',
      description: 'Manage settings that apply to your organization.',
      backgroundColor: 'var(--p-color-avatar-five-bg-fill)',
      textColor: 'var(--p-color-avatar-five-text-on-bg-fill)',
      items: [
        {
          title: 'Billing',
          description: 'Manage your billing information and view your plan details',
          icon: ReceiptDollarIcon,
          url: '/settings/billing'
        },
        {
          title: 'Referrals',
          description: 'View and configure your Junip referral URL',
          icon: ChatReferralIcon,
          url: '/settings/referral'
        },
        {
          title: 'Team',
          description: 'Manage your team and invite new members',
          icon: TeamIcon,
          url: '/settings/team'
        },
        {
          title: 'Custom apps',
          description: 'Manage your organization’s custom apps.',
          icon: CodeIcon,
          url: '/integrations/custom'
        }
      ]
    },
    {
      title: 'Store settings',
      description: 'Manage settings related to this store’s content, messages, and customers.',
      backgroundColor: 'var(--p-color-avatar-one-bg-fill)',
      textColor: 'var(--p-color-avatar-one-text-on-bg-fill)',
      items: [
        {
          title: 'Store information',
          description: 'Edit your store’s physical address and URL and check your connection status',
          icon: StoreIcon,
          url: '/settings/store'
        },
        {
          title: 'Branding',
          description: 'Edit your store’s logo and brand colours',
          icon: ColorIcon,
          url: '/settings/branding'
        },
        {
          title: 'On-site displays',
          description: 'Allow Junip displays on your site',
          icon: DesktopIcon,
          url: '/settings/on_site'
        },
        organization?.two_point_oh
          ? {
            title: 'Review management',
            description: 'Control which reviews should be held in pending state instead of auto-published',
            icon: FlagIcon,
            url: '/settings/moderation'
          } : undefined,
        {
          title: 'Transactional flows',
          description: 'Manage transactional messages sent to your customers',
          icon: EmailNewsletterIcon,
          url: '/settings/transactional-flows'
        }
      ].filter(setting => !!setting)
    },
    {
      title: 'User settings',
      description: 'Manage your personal settings.',
      backgroundColor: 'var(--p-color-avatar-four-bg-fill)',
      textColor: 'var(--p-color-avatar-four-text-on-bg-fill)',
      items: [
        {
          title: 'Account information',
          description: 'Accept team invitations, update your password, and edit your personal information',
          icon: ProfileIcon,
          url: '/settings/user'
        },
        {
          title: 'Notifications',
          description: 'Manage the email notifications you receive from Junip',
          icon: NotificationIcon,
          url: '/settings/notifications'
        }
      ]
    }
  ];

  return (
    <Page title="Settings">
      <BlockStack gap="500">
        {sections.map((section, index) => (
          <BlockStack key={index} gap="300">
            <div className="free-wrapper">
              <BlockStack gap="100">
                <Text variant="headingMd">
                  {section.title}
                </Text>
                <Text tone="subdued" variant="bodyMd">
                  {section.description}
                </Text>
              </BlockStack>
            </div>
            <Card padding="0">
              <ResourceList
                resourceName={{ singular: 'item', plural: 'items' }}
                items={section.items}
                renderItem={(item) => {
                  const { title, description, icon, url } = item;
                  return (
                    <ResourceList.Item
                      id={title}
                      url={url}
                      accessibilityLabel={`View details for ${title}`}
                      media={
                        <div
                          style={{
                            backgroundColor: section.backgroundColor,
                            fill: section.textColor,
                            color: section.textColor,
                            borderRadius: 'var(--p-border-radius-200)',
                            height: '40px',
                            width: '40px',
                          }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Icon source={icon} />
                        </div>
                      }
                    >
                      <Text variant="bodyMd" fontWeight="semibold">
                        {title}
                      </Text>
                      <Text tone="subdued" variant="bodyMd">
                        {description}
                      </Text>
                    </ResourceList.Item>
                  );
                }}
              />
            </Card>
          </BlockStack>
        ))}
      </BlockStack>
    </Page>
  );
}
